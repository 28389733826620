import { Directive, Input , ElementRef, Renderer2, OnInit} from '@angular/core';

@Directive({
  selector: '[appSimNao]'
})
export class SimNaoDirective implements OnInit{
    
  @Input() value: boolean;

  constructor(private elRef: ElementRef, private render: Renderer2){

  }

  ngOnInit(){

      if(this.value){

        const text = this.render.createText('Sim');
  
        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-primary");
  
      }else{

        const text = this.render.createText('Não');
  
        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-danger");
  
      }

    }
    

}

