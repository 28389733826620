import { Directive, Input , ElementRef, Renderer2, OnInit} from '@angular/core';

@Directive({
  selector: '[appErro]'
})
export class ErroDirective implements OnInit{
    
  @Input() value: boolean;

  constructor(private elRef: ElementRef, private render: Renderer2){

  }

  ngOnInit(){

      if(this.value){

        const text = this.render.createText('Erro');
  
        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-danger");
  
      }else{

        const text = this.render.createText('Sucesso');
  
        this.render.appendChild(this.elRef.nativeElement, text);
        this.render.addClass(this.elRef.nativeElement, "badge");
        this.render.addClass(this.elRef.nativeElement, "badge-success");
  
      }

    }
    

}

